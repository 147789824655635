import React, { useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Link from 'next/link';

import Logo from 'components/content/Logo';

import Hamburger from 'components/controls/Hamburger'

function itemIsActive(router, item) {
  if (!router) {
    return false;
  }

  if (
    (router.pathname === '/' && item.url === '/') ||
    (router.pathname.indexOf(item.url) === 0 && item.url !== '/') ||
    (item.url === '/guides' && router.pathname.indexOf('/[qualificationSlug]') > -1)
  ) {
    return true;
  }

  return false;
}

export default function SiteNav() {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const items = [
    { url: '/', text: 'Home' },
    { url: '/guides', text: 'Study Guides' },
    { url: '/resources', text: 'Exam Resources' },
    { url: '/articles', text: 'Articles' },
    { url: '/webinars', text: 'Webinars' },
    { url: '/wellbeing', text: 'Wellbeing' },
    { url: '/tuition-providers', text: 'Tuition Providers' },
    { url: '/support', text: '1-2-1 Support' },
  ];

  const toggleOpen = () => setOpen(!open);

  const cx = classNames('site-nav', {
    'site-nav--open': open,
  })

  return (
    <header className={cx}>
      <div className="site-nav__top">
        <div className="site-nav__logo">
          <Logo />
        </div>

        <div className="site-nav__mobile">
          <div className="site-nav__burger">
            <Hamburger open={open} onClick={toggleOpen} />
          </div>
        </div>
      </div>
      <nav className="site-nav__bottom">
        <ul className="site-nav__list">
          {items.map(item => {
            const isActive = itemIsActive(router, item);

            if (item.hide) return null;

            return (
              <li
                className={classNames('site-nav__item', {
                  'site-nav__item--support': item.support,
                })}
                key={item.url}
              >
                <Link href={item.url}>
                  <a
                    className={classNames('site-nav__link', {
                      'site-nav__link--active': isActive
                    })}
                    href={item.url}
                    onClick={toggleOpen}
                  >
                    {item.text}
                  </a>
                </Link>
              </li>
            );
          })}
          <li className="site-nav__item site-nav__item--login" key="login">
            <Link href="/login">
              <a
                href="/login"
                className={classNames('site-nav__link', {
                  'site-nav__link--active': router && router.pathname === '/login'
                })}
                onClick={toggleOpen}
              >
                Log in
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
