import React from 'react';
import classNames from 'classnames';
import Icon from 'components/content/Icon';
import { node, oneOf, string, bool, func } from 'prop-types';

export default class Button extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.renderContents = this.renderContents.bind(this);
  }

  handleKeyDown(e) {
    const { onClick } = this.props;
    if (e.keyCode === 32 && onClick) {
      onClick(e);
    }
  }

  renderContents() {
    const { loading, loadingText, children } = this.props;
    return loading ? (
      <span>
        <Icon type="spinner" spin /> {loadingText}
      </span>
    ) : (
      children
    );
  }

  render() {
    const {
      type,
      buttonType,
      className,
      disabled,
      block,
      size,
      onClick,
      loading,
      href,
      target,
      rel,
      as,
      loadingText,
      ...props
    } = this.props;

    const classNameButton = classNames('button', {
      [`button--${type}`]: type,
      [`${className}`]: className,
      'button--block': block,
      'button--loading': loading,
      [`button--${size}`]: size !== 'default',
    });

    if (href !== '') {
      return (
        <a
          className={classNameButton}
          disabled={disabled || loading}
          onClick={onClick}
          onKeyDown={this.handleKeyDown}
          href={href}
          target={target !== '' ? target : null}
          rel={rel !== '' ? rel : null}
          {...props}
        >
          {this.renderContents()}
        </a>
      );
    }

    const Tag = as || 'button';

    return (
      <Tag
        type={buttonType}
        className={classNameButton}
        disabled={disabled || loading}
        onClick={onClick}
        onKeyDown={this.handleKeyDown}
        {...props}
      >
        {this.renderContents()}
      </Tag>
    );
  }
}

Button.propTypes = {
  children: node.isRequired,
  buttonType: oneOf(['button', 'submit']),
  className: string,
  disabled: bool,
  type: oneOf([
    'primary',
    'default',
    'secondary',
    'link',
    'outline',
    'green-dark',
    'outline-white',
    'white',
    'ghost',
    'notes',
  ]),
  size: oneOf(['default', 'small']),
  onClick: func,
  block: bool,
  loading: bool,
  loadingText: string,
  href: string,
  target: string,
  rel: string,
  as: string,
};

Button.defaultProps = {
  buttonType: 'button',
  className: '',
  disabled: false,
  type: 'default',
  block: false,
  size: 'default',
  onClick: () => {},
  loading: false,
  loadingText: 'Loading...',
  href: '',
  target: '',
  rel: '',
  as: 'button',
};
