import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/content/Icon';

export default class HighlightBox extends React.Component {
  render() {
    const { icon, title, children, color, noHeight } = this.props;

    const classNameHighlightBox = classNames('highlight-box', {
      [`highlight-box--${color}`]: color,
      'highlight-box--natural-height': noHeight,
    });
    return (
      <section className={classNameHighlightBox}>
        {icon !== '' ? (
          <Icon type={icon} className="highlight-box__icon" />
        ) : (
          false
        )}
        {title !== '' ? (
          <h2 className="highlight-box__title">{title}</h2>
        ) : (
          false
        )}

        <div className="highlight-box__content">{children}</div>
      </section>
    );
  }
}

HighlightBox.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  color: PropTypes.string,
  noHeight: PropTypes.bool,
};

HighlightBox.defaultProps = {
  icon: '',
  title: '',
  children: '',
  color: '',
  noHeight: false,
};
