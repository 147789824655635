import React from 'react';

import MarkerScript from './Marker';
import TagManagerScript from './TagManagerScript';
import GoogleFontsScript from './GoogleFonts';
import MouseflowScript from './Mouseflow';

export default function Scripts() {
  return (
    <>
      <GoogleFontsScript />
      <TagManagerScript />
      <MarkerScript />
      <MouseflowScript />
    </>
  );
}
