import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import { useQuery } from '@apollo/react-hooks';

import initApollo from '../init-apollo';

export const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      id
      siebel_id
      current_syllabus_name
      current_level_id
      current_subject_code
      unsupported_qualification
    }
  }
`;

const domain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
const authMessage = 'Please log in to access all areas of the Study Hub';

export function isLoggedIn(apolloClient) {
  return apolloClient
    .query({ query: CURRENT_USER_QUERY, fetchPolicy: 'network-only' })
    .then(({ data, error }) => {
      return Boolean(data?.currentUser && !error);
    })
    .catch(() => false);
}

// Use user hook to get use and if they are logged in
export function useUser() {
  const { data, error } = useQuery(CURRENT_USER_QUERY);

  const loggedIn = Boolean(data?.currentUser && !error);

  return {
    user: data?.currentUser || {},
    isLoggedIn: loggedIn
  };
}

// export this as getServerSideProps from a page
// either gives you login state and user object or redirects
export function withLoggedIn({ required } = {}) {
  return async (ctx) => {
    const apolloClient = initApollo({}, ctx);

    const { data, error } = await apolloClient.query({
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    });

    const loggedIn = Boolean(data?.currentUser && !error);

    if (required && !loggedIn) {
      return {
        redirect: {
          destination: `/login?message=${authMessage}&from=${ctx.asPath || ctx.pathname}`,
          permanent: false,
        }
      }
    }

    return {
      props: {
        isLoggedIn: loggedIn,
        user: !error && data?.currentUser,
      }
    }
  }
}

export function login(token, cb) {
  Cookies.set('token', token, { domain, expires: 6 });
  if (cb) cb();
  return null;
}

export function logout() {
  Cookies.remove('token', { domain });
  location.href = '/'; // eslint-disable-line no-restricted-globals
}
