import React from 'react';
import { node } from 'prop-types';

export default function App({ children }) {
  return <div className="app">{children}</div>;
}

App.propTypes = {
  children: node.isRequired,
};
