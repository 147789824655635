import React from 'react';
import { node } from 'prop-types';

export default function PageMain({ children }) {
  return <div className="page__main">{children}</div>;
}

PageMain.propTypes = {
  children: node.isRequired,
};
