import React from 'react';
import { node } from 'prop-types';

export default function PageAside({ children }) {
  return <aside className="page__aside">{children}</aside>;
}

PageAside.propTypes = {
  children: node.isRequired,
};
