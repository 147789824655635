/* globals fbq */

import ReactGA from 'react-ga';

class Analytics {
  constructor(gaKey) {
    ReactGA.initialize(gaKey);
  }

  event({ category, action, label }) {
    if (typeof window !== 'undefined') {
      try {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.event({ category, action, label });
      } catch (err) {
        // Ignore GA errors that might cause other things to fail
      }
    }
  }

  exception(description, fatal = false) {
    if (typeof window !== 'undefined') {
      ReactGA.set({ page: window.location.pathname });
      ReactGA.exception({ description, fatal });
    }
  }

  logModalView(name) {
    if (typeof window !== 'undefined') {
      ReactGA.set({ page: window.location.pathname });
      ReactGA.modalview(name);
    }
  }

  logPageView() {
    if (typeof window !== 'undefined') {
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);
      fbq('track', 'PageView');
    }
  }
}

const analytics = new Analytics(
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY
);

export default analytics;
