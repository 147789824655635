import React, { useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import Link from 'next/link';

import Icon from 'components/content/Icon';
import Logo from 'components/content/Logo';

import { useUser, logout } from 'lib/helpers/login';

const SLUG_QUERY = gql`
  query subjects($where: JSON!) {
    subjects(where: $where) {
      id
      Code
      level {
        id
        Slug
        Name
        syllabus {
          id
          Slug
          course {
            id
            Slug
          }
        }
      }
    }
  }
`;

function itemIsActive(router, item) {
  if (!router) {
    return false;
  }

  if (
    router.pathname.indexOf(item.url) === 0 ||
    (item.text === 'Guides' && router.pathname.indexOf('/[qualificationSlug]') === 0) ||
    (item.text === 'Notes' && router.pathname.indexOf('/notes') > -1) ||
    (item.text === 'Resources' && router.pathname.indexOf('/resources') > -1)
  ) {
    return true;
  }

  return false;
}

export default function AppNav() {
  const { user } = useUser();
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <Query
      query={SLUG_QUERY}
      variables={{
        where: {
          Code: user.current_subject_code,
          level: {
            siebelId: user.current_level_id,
            syllabus: {
              Name: user.current_syllabus_name
            }
          }
        }
      }}
    >
      {({ loading, error, data: { subjects } = {} }) => {
        let guideLink = '/guides';
        let guideAs = '/guides';
        let resourcesLink = '/resources';

        if (!loading && !error && subjects && subjects[0]) {
          const subject = subjects[0];
          const { level: { syllabus } } = subject;
          guideLink = '/[qualificationSlug]/[syllabusSlug]'
          guideAs = `/${syllabus.course.Slug}/${syllabus.Slug}`;

          if (syllabus.Slug > '2015') {
            resourcesLink = `/resources?level=${subject.level.id}&subject=${subject.id}`;
          }
        }

        const navItems = [
          { url: '/dashboard', text: 'Dashboard', icon: 'dashboard' },
          { url: guideLink, as: guideAs, text: 'Guides', icon: 'course' },
          { url: resourcesLink, text: 'Resources', icon: 'resource' },
          { url: '/articles', text: 'Articles', icon: 'article' },

        ];

        const drawerItems = [
          { url: '/settings', text: 'Settings', icon: 'sliders', settings: true },
          { url: '/support', text: 'Support', icon: 'support' },
          { url: '/tuition-providers', text: 'Tuition', icon: 'graduation-cap' },
          { url: '/wellbeing', text: 'Wellbeing', icon: 'wellbeing' },
          { url: '/webinars', text: 'Webinars', icon: 'webinar' },
        ]

        const cx = classNames('app-nav', {
          'app-nav--open': open,
        })

        return (
          <header className={cx}>
            <nav className="app-nav__inner">
              <div className="app-nav__logo">
                <Logo />
              </div>
              <ul className="app-nav__list">
                {navItems.map(item => {
                  const isActive = itemIsActive(router, item) && !open;

                  return (
                    <li className="app-nav__item" key={item.url}>
                      <Link href={item.url} as={item.as || item.url}>
                        <a
                          href={item.as || item.url}
                          className={classNames('app-nav__link', {
                            'app-nav__link--active': isActive
                          })}
                          onClick={() => setOpen(false)}
                        >
                          <Icon type={item.icon} /> {item.text}
                        </a>
                      </Link>
                    </li>
                  );
                })}
                <li className="app-nav__item app-nav__item--more">
                  <a
                    href="#drawer"
                    className={classNames('app-nav__link', {
                      'app-nav__link--active': open
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleOpen();
                    }}
                  >
                    <Icon type="options" /> More
                  </a>
                </li>
              </ul>
              <ul className="app-nav__list app-nav__list--drawer">
                <li className="app-nav__item app-nav__item--logout">
                  <a
                    href="#logout"
                    className="app-nav__link"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                      logout();
                    }}
                  >
                    <Icon type="logout" /> Log out
                  </a>
                </li>
                {drawerItems.map(item => {
                  const isActive = itemIsActive(router, item);

                  return (
                    <li
                      className={classNames('app-nav__item', {
                        'app-nav__item--settings': item.settings,
                      })}
                      key={item.url}
                    >
                      <Link href={item.url} as={item.as || item.url}>
                        <a
                          href={item.as || item.url}
                          className={classNames('app-nav__link', {
                            'app-nav__link--active': isActive
                          })}
                          onClick={() => setOpen(false)}
                        >
                          <Icon type={item.icon} /> {item.text}
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </header>
        );
      }}
    </Query>
  );
}
