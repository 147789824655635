/* eslint-disable react/no-danger */
import React from 'react';
import { useUser } from 'lib/helpers/login';

export default function CookieConsent() {
  const { isLoggedIn } = useUser();
  if (isLoggedIn) {
    if (typeof document !== 'undefined' && document.querySelector('.cc-banner')) {
      document.querySelector('.cc-banner').style.display = 'none'
      document.querySelector('.cc-revoke').style.display = 'none';
    }

    return null;
  }

  return (
    <>
      <script src="/scripts/cookie-consent.min.js" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.addEventListener("load", function () {
              if (window.cookieconsent) {
                window.cookieconsent.initialise({
                  "palette": {
                    "popup": {
                      "background": "#000000"
                    },
                    "button": {
                      "background": "#ffffff"
                    }
                  },
                  "type": "opt-in"
                });
              }
            });
          `,
        }}
      />
    </>
  );
}
