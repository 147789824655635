import Router from 'next/router';

export const studyGuidesUrl = (subjectCode, topicLetter, sessionNumber) => {
  const levels = ['', 'operational', 'management', 'strategic'];
  const levelNum = subjectCode.slice(-1);
  const level = subjectCode.length > 2 ? 'certificate' : levels[levelNum];

  const course = subjectCode.length > 2 ? 'certba/2017' : `proqual/2015`;

  let url = `/${course}/${level}/${subjectCode.toUpperCase()}`;

  if (topicLetter) {
    url = `${url}/${topicLetter.toUpperCase()}`;
  }

  if (sessionNumber) {
    url = `${url}/${sessionNumber}`;
  }

  return url;
};

const Redirect = (target, context = {}) => {
  if (context.res) {
    // server 303: "See other"
    context.res.writeHead(303, { Location: target });
    context.res.end();
  } else if (typeof window !== 'undefined') {
    // In the browser, we just pretend like this never happened
    Router.replace(target);
  }
};

export default Redirect;
