import React from 'react';

import { useUser } from 'lib/helpers/login';

import AppNav from 'components/controls/AppNav';
import SiteNav from 'components/controls/SiteNav';

export default function Header() {
  const { isLoggedIn } = useUser();

  return (
    <>
      {isLoggedIn ? (
        <AppNav />
      ) : (
        <SiteNav />
      )}
    </>
  );
}
