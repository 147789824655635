import React from 'react';
import { node } from 'prop-types';
import { Query } from '@apollo/react-components';

import { CURRENT_USER_QUERY } from 'lib/helpers/login';

export default function HeartBeat({ children }) {
  return (
    <Query query={CURRENT_USER_QUERY} errorPolicy="ignore" pollInterval={30000}>
      {() => (
        <>{children}</>
      )}
    </Query>
  );
}

HeartBeat.propTypes = {
  children: node.isRequired,
};
