import React from 'react';

import Link from 'next/link';

export default function Logo() {
  return (
    <Link href="/">
      <a className="logo">
        CGMA<span className="logo__r">&reg;</span> Study Hub
      </a>
    </Link>
  );
}
