import React from 'react';
import { node, bool } from 'prop-types';
import { useUser } from 'lib/helpers/login';
import classNames from 'classnames';

import Aside from './Aside';
import Main from './Main';
import Footer from './Footer';
import Full from './Full';

export default function Page({ children, fullWidth }) {
  const { isLoggedIn } = useUser();
  const cx = classNames('page', {
    'page--logged-in': isLoggedIn,
    'page--full-width': fullWidth,
  });
  return (
    <div className={cx}>
      <div className="page__inner">{children}</div>
    </div>
  );
}

Page.propTypes = {
  children: node.isRequired,
  fullWidth: bool
};

Page.defaultProps = {
  fullWidth: false
};

Page.Aside = Aside;
Page.Main = Main;
Page.Footer = Footer;
Page.Full = Full;

