import { node } from 'prop-types';
import React from 'react';
import * as Sentry from '@sentry/browser';
import ErrorTemplate from 'components/content/ErrorTemplate';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
    this.setState({ error });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <ErrorTemplate statusCode={error.statusCode || 500} message={error.message} />
      )
    }
    return children
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
}
