import React, { useEffect } from 'react';
import { array, number, string } from 'prop-types';
import Head from 'next/head';
import Main from 'components/composition/Main';
import Page from 'components/canvas/Page';
import HighlightBox from 'components/canvas/HighlightBox';
import Icon from 'components/content/Icon';
import Spacing from 'components/composition/Spacing';
import Title from 'components/content/Title';
import Button from 'components/controls/Button';
import redirect from 'lib/helpers/redirect';
import analytics from 'lib/analytics';
import Link from 'next/link';
import Lead from '../Lead';

const authMessage = 'Please log in to access all areas of the Study Hub';

export default function ErrorTemplate({ statusCode, message, matches }) {
  useEffect(() => {
    if (message === 'GraphQL error: You must be authenticated to make this request') {
      const from = typeof window !== 'undefined' && window.location.pathname ? `&from=${window.location.pathname}` : '';
      redirect(`/login?message=${authMessage}${from}`);
    }

    const exception = statusCode === 404 ? '404 - Page not found' : `${statusCode} - ${message}`;

    analytics.exception(exception);
  }, []);

  return (
    <Main>
      <Head>
        <title>Error {statusCode} | CGMA Study Hub</title>
      </Head>
      <Page>
        <Page.Main>
          {matches && statusCode === 404 ?  (
            <>
              <Title theme="brand-primary" spacing>Page not found</Title>
              <Spacing bottom="lg">
                <Lead>Sorry, we couldn&apos;t find the page you are looking for.</Lead>
              </Spacing>
              <div>
                <p>Did you mean one of these?</p>
                <ul>

                  {matches.map(el => (
                    <li key={el.target}>
                      <Link
                        href={el.target}
                      >
                        <a href={el.target}>{el.target}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <>
              <Spacing bottom="lg">
                <Title level={1} theme="brand-primary" spacing>{`${(statusCode || 'Ut oh')}`}</Title>
                <Lead>An error occurred whilst loading the page.</Lead>
              </Spacing>
              <Spacing bottom="lg">
                <p>Our team has been notified and will look into the issue.</p>
                <Button
                // eslint-disable-next-line no-restricted-globals
                  onClick={() => { location.reload(); }}
                >
                  Try again
                </Button>
              </Spacing>
            </>
          )}

        </Page.Main>
        <Page.Aside>
          <HighlightBox color="grey-light" noHeight>
            <Spacing bottom="md">
              <h3>Need assistance?</h3>
              <p>
                If you continue to experience problems
                please get in touch with our team.
              </p>
              <p>
                <a href="mailto:cima.contact@aicpa-cima.com">
                  <strong>Contact CIMA <Icon type="arrow-right" /></strong>
                </a>
              </p>
            </Spacing>
          </HighlightBox>
        </Page.Aside>
      </Page>
    </Main>
  );
}

ErrorTemplate.propTypes = {
  statusCode: number,
  message: string,
  matches: array,
};

ErrorTemplate.defaultProps = {
  statusCode: 0,
  message: '',
  matches: [],
};
