import React from 'react';
import { node } from 'prop-types';

export default function Lead({ children }) {
  return <div className="lead">{children}</div>;
}

Lead.propTypes = {
  children: node.isRequired,
};
