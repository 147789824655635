import Script from 'next/script';
import React from 'react';

export default function GoogleFontsScript() {
  return (
    <Script id="google-font">
      {`
        WebFontConfig = {
          google: {
            families: ['Roboto:100,300,400,400i,700&display=swap'],
          },
        };

        (function(d) {
          var wf = d.createElement('script'), s = d.scripts[0];
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
          wf.async = true;
          s.parentNode.insertBefore(wf, s);
        })(document);
      `}
    </Script>
  );
}
