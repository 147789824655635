import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { any } from 'prop-types';

import ReactModal from 'react-modal';
import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';

import AppComposition from 'components/composition/App';
import Footer from 'components/composition/Footer';
import Header from 'components/composition/Header';
import CookieConsent from 'components/controls/CookieConsent';
import HeartBeat from 'components/composition/HeartBeat';
import ErrorBoundary from 'components/content/ErrorBoundary';

import { useApollo } from 'lib/init-apollo';

import 'lib/analytics';

import '../styles/main.scss';
import Scripts from 'components/content/Scripts/Scripts';

Sentry.init({
  dsn: process.env.SENTRY_DSN
});

ReactModal.setAppElement('#__next');

function App({
  Component,
  pageProps,
}) {
  const apolloClient = useApollo(pageProps);
  const { pathname } = useRouter();
  const isNotesExportLoading = pathname === '/notes/exporting';

  // If this is a notes exporting loading page,
  // all we need is the content
  // using this method to get around adblocker on window open
  // https://stackoverflow.com/questions/43283454/open-blob-objecturl-in-chrome
  if (isNotesExportLoading) {
    return (
      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} />
      </ApolloProvider>
    );
  }

  return (
    <AppComposition>
      <Head>
        <title>CGMA Study Hub</title>
        <meta
          name="viewport"
          content="width=device-width,height=device-height,initial-scale=1"
        />
      </Head>
      <ApolloProvider client={apolloClient}>
        <HeartBeat>
          <Header />
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
          <Footer />
          <CookieConsent />
        </HeartBeat>
      </ApolloProvider>
      <Scripts />
    </AppComposition>
  );
}

export default App;

App.propTypes = {
  Component: any.isRequired,
  pageProps: any.isRequired,
}
