import React from 'react';
import { node } from 'prop-types';

export default function Main({ children }) {
  return (
    <main className="main">
      <div className="main__content">{children}</div>
    </main>
  );
}

Main.propTypes = {
  children: node.isRequired,
};
