import React from 'react';
import classNames from 'classnames';
import { string, bool, oneOf } from 'prop-types';

export default function Icon({ type, className, spin, title, state, size, theme, shape }) {
  const classNameIcon = classNames('icon', {
    [`icon-${type}`]: type,
    [`${className}`]: className,
    'icon--spin': spin,
    [`icon--${state}`]: state !== 'inherit',
    [`icon--${size}`]: size !== 'inherit',
    [`icon--${theme}`]: theme !== 'inherit',
    [`icon--${shape}`]: shape,
  });

  return <i className={classNameIcon} title={title} aria-hidden="true" />;
}

Icon.propTypes = {
  className: string,
  type: string.isRequired,
  spin: bool,
  title: string,
  state: oneOf(['inherit', 'info', 'success', 'warning', 'danger']),
  size: string,
  theme: string,
  shape: oneOf(['circle', 'circle-outline']),
};

Icon.defaultProps = {
  className: '',
  title: '',
  spin: false,
  state: 'inherit',
  size: 'inherit',
  theme: 'inherit',
  shape: undefined,
};
