import React from 'react';
import { string, node, number, bool, oneOf } from 'prop-types';
import classNames from 'classnames';

export default function Title({ theme, children, level, spacing, className, size, responsive, weight }) {
  const cx = classNames('title', {
    [`title--${theme}`]: theme,
    'title--spacing': spacing,
    [`${className}`]: className,
    [`title--${size}`]: size,
    'title--responsive': responsive,
    [`title--weight-${weight}`]: weight,
  });
  if (level === 1) return <h1 className={cx}>{children}</h1>;
  if (level === 2) return <h2 className={cx}>{children}</h2>;
  if (level === 3) return <h3 className={cx}>{children}</h3>;
  if (level === 4) return <h4 className={cx}>{children}</h4>;

  return false;
}

Title.propTypes = {
  className: string,
  children: node.isRequired,
  level: number,
  spacing: bool,
  size: string,
  responsive: bool,
  weight: string,
  theme: oneOf([
    'brand-primary',
    'brand-grey',
    'blue',
    'blue-dark',
    'green',
    'green-dark',
    'orange',
    'orange-dark',
    'black',
    'white',
  ]),
};

Title.defaultProps = {
  className: '',
  level: 2,
  size: undefined,
  spacing: false,
  theme: undefined,
  responsive: false,
  weight: undefined,
};
