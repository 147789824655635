import Script from 'next/script';
import React from 'react';

export default function MouseflowScript() {
  if (!process.env.NEXT_PUBLIC_MOUSEFLOW_KEY) {
    return null;
  }

  return (
    <Script id="mouseflow" strategy="lazyOnload">
      {`
        window._mfq = window._mfq || [];

        (function() {
            var mf = document.createElement("script");
            mf.async = true;
            mf.defer = true;
            mf.src = "https://cdn.mouseflow.com/projects/${process.env.NEXT_PUBLIC_MOUSEFLOW_KEY}.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
        })();
      `}
    </Script>
  );
}
