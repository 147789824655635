import React from 'react';
import { node } from 'prop-types';

export default function PageFull({ children }) {
  return <div className="page__full">{children}</div>;
}

PageFull.propTypes = {
  children: node.isRequired,
};
