import React from 'react';
import classNames from 'classnames';

import { useUser } from 'lib/helpers/login';

export default function Footer() {
  const { isLoggedIn } = useUser();
  const cx = classNames('footer', {
    'footer--logged-in': isLoggedIn,
  });
  const year = new Date().getFullYear();

  return (
    <footer className={cx} role="contentinfo">
      <div className="footer__content">
        <div className="footer__inner">
          <ul className="footer__links-list">
            <li className="footer__links-list-item">
              <a href="https://www.aicpa-cima.com/help/terms-and-conditions?utm_source=cima&utm_medium=web&utm_content=footer&utm_campaign=study-planner" target="_blank" rel="noopener">Terms &amp; Conditions</a>
            </li>
            <li className="footer__links-list-item">
              <a href="https://www.aicpa-cima.com/help/privacy-policy/?utm_source=cima&utm_medium=web&utm_content=footer&utm_campaign=study-planner" target="_blank" rel="noopener">Privacy & Cookies Policy</a>
            </li>
            <li className="footer__links-list-item">
              <a href="https://www.aicpa-cima.com/help/website-accessibility/?utm_source=cima&utm_medium=web&utm_content=footer&utm_campaign=study-planner" target="_blank" rel="noopener">Accessibility</a>
            </li>
          </ul>
          <p className="footer__copyright">&copy;2019 - {year} Chartered Institute of Management Accountants</p>
        </div>
      </div>
    </footer>
  );
}
