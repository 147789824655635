import React from 'react';
import classNames from 'classnames';
import { any, bool } from 'prop-types';

export default function Hamburger({ onClick, open }) {
  const cx = classNames('hamburger', {
    'hamburger--open': open,
  });

  const text = open ? 'Close menu' : 'Open menu';

  return (
    <button className={cx} type="button" onClick={onClick}>
      <span className="hamburger__line" />
      <span className="hamburger__line">
        <span className="hamburger__line" />
      </span>
      <span className="hamburger__line" />
      <span className="sr-only">{text}</span>
    </button>
  )
}

Hamburger.propTypes = {
  onClick: any.isRequired,
  open: bool.isRequired,
};
