import { useMemo } from 'react';
import { ApolloClient, InMemoryCache } from 'apollo-boost';
import fetch from 'isomorphic-unfetch';
import merge from 'deepmerge';
import { SentryLink } from 'apollo-link-sentry';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import isEqual from 'lodash/isEqual';

export const APOLLO_STATE_PROPERTY_NAME = '__APOLLO_STATE__';

let apolloClient = null;

const httpOptions = (ctx, isBrowser) => {
  const API_URL = process.env.NEXT_PUBLIC_API_URL; // eslint-disable-line prefer-destructuring
  const SERVER_API_URL = process.env.SERVER_API_URL; // eslint-disable-line prefer-destructuring
  const cookie = (ctx && ctx.req && ctx.req.headers.cookie) || '';

  const enchancedFetch = (url, opts) => fetch(url, {
    ...opts,
    headers: { ...opts.headers, Cookie: cookie },
  });

  return {
    uri: (isBrowser ? API_URL : SERVER_API_URL) || '', // Server URL (must be absolute)
    credentials: 'include', // Additional fetch() options like `credentials` or `headers`
    // Use fetch() polyfill on the server
    fetch: !isBrowser && enchancedFetch,
    headers: { cookie }
  }
}

function create(ctx) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const isBrowser = typeof window !== 'undefined';

  const httpLink = new BatchHttpLink(httpOptions(ctx, isBrowser));

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      new SentryLink(),
      httpLink,
    ]),
    cache: new InMemoryCache(),
  });
}

export default function initApollo(initialState, ctx) {
  const client = apolloClient ?? create(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return client;
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function addApolloState(
  client,
  pageProps,
) {
  if (!pageProps?.props) {
    return pageProps;
  }

  return {
    ...pageProps,
    props: {
      ...pageProps.props,
      APOLLO_STATE_PROPERTY_NAME: client.cache.extract()
    }
  };
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROPERTY_NAME];
  return useMemo(() => initApollo(state), [state]);
}
