import React from 'react';
import { node } from 'prop-types';

export default function PageFooter({ children }) {
  return <footer className="page__footer">{children}</footer>;
}

PageFooter.propTypes = {
  children: node.isRequired,
};
