import React from 'react';
import { node, oneOf } from 'prop-types';
import classNames from 'classnames';

export default function Spacing({ children, top, left, bottom, right }) {
  const cx = classNames('spacing', {
    [`spacing--top-${top}`]: top !== '',
    [`spacing--left-${left}`]: left !== '',
    [`spacing--bottom-${bottom}`]: bottom !== '',
    [`spacing--right-${right}`]: right !== '',
  });
  return <div className={cx}>{children}</div>;
}

Spacing.propTypes = {
  children: node,
  top: oneOf(['', 'xs', 'sm', 'md', 'lg', 'xl']),
  left: oneOf(['', 'xs', 'sm', 'md', 'lg', 'xl', 'fluid']),
  bottom: oneOf(['', 'xs', 'sm', 'md', 'lg', 'xl']),
  right: oneOf(['', 'xs', 'sm', 'md', 'lg', 'xl', 'fluid']),
};

Spacing.defaultProps = {
  children: null,
  top: '',
  left: '',
  bottom: '',
  right: '',
};
